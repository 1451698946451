<template>
  <div class="page-box">
    <div class="pro-men">
      <div class="pro-text">
        <span class="home-icon"><i class="el-icon-s-home"></i></span>
        {{$t('public.ProductCenter')}} <i class="el-icon-d-arrow-right"></i> {{$t('public.productDetails')}}
      </div>
    </div>
    <div class="pro-intr">
      <div class="pro-intr-cont">
        <div class="left">
          <!-- <img class="pro-intr-img" :src="imgShop" alt="" /> -->
          <div style="width:675px">
            <el-carousel ref="myCarousel" :interval="5000" :initial-index="shopIndex"
            @change="carouselChange"
             arrow="always" width="675px" height="496px">
              <el-carousel-item v-for="(item, index) in thumbnailList" :key="index">
                <img class="pro-intr-img" :src="imgUrl + item"  alt="" style="width: 100%;" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="right">
          <div class="titlea">{{infoDeta.name}}</div>
          <div class="cont">
            <div class="titlesc">ITEM：{{infoDeta.id}}</div>
            <div class="introduce-box">
              <div class="introduce-left">介绍</div>
              <div class="introduce-right" v-html="infoDeta.summary"></div>
            </div>
          </div>
          <div class="contact">
            <img class="contact1" src="~@/assets/img/contact.png" alt="">
            <span class="contact2">联系咨询:</span>
            <span class="contact3">{{infoAddres.consulting}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="scroll-box">
      <div class="scroll">
        <div class="list" :class="shopIndex == index? 'active':''"
        @click="shopDetaBig(item,index)" 
        v-for="(item,index) in thumbnailList" :key="index">
          <img :src="imgUrl+item" alt="">
        </div>
        <!-- <div class="list"><img src="~@/assets/img/pro2.png" alt=""></div>
        <div class="list"><img src="~@/assets/img/pro2.png" alt=""></div>
        <div class="list"><img src="~@/assets/img/pro2.png" alt=""></div>
        <div class="list"><img src="~@/assets/img/pro2.png" alt=""></div> -->
      </div>
    </div>

    <div class="shop-box">
      <div class="left">
        <div class="titlesm">
          <img src="~@/assets/img/shop1.png" alt="">{{$t('public.MerchandiseDetails')}}
        </div>
        <div class="cont">
          <div class="cont-padding" v-html="infoDeta.detail"></div>
        </div>
      </div>
      <div class="right">
        <div class="titlesm">
          <img src="~@/assets/img/shop1.png" alt="">{{$t('public.RecommendedProducts')}}
        </div>
        <div class="cont">
          <ProductList type="productDeta"></ProductList>
        </div>
      </div>
    </div>
    <FormInput></FormInput>

  </div>
</template>
<script>
import { getAction } from "@/api/manage";
export default {
  name: "index",

  data() {
    return {
      id: '',
      infoDeta: {},
      imgShop: '',
      shopIndex: 0,
      imgUrl: process.env.VUE_APP_BASE_IMG,
      thumbnailList: [],
      infoAddres: {}
    }
  },
  watch: {
     '$route.query': function(newQuery) {
      console.log('新的查询字符串为', newQuery);
      this.id = newQuery.id
      this.shopDetails()
    }
  },
  mounted() {
    // this.infoDeta = JSON.parse(this.$route.query.item)
    // this.imgShop = this.imgUrl+this.infoDeta.thumbnailList[0]
    // console.log(this.infoDeta.id,111111111);
    this.infoAddres =JSON.parse(localStorage.getItem('infoDeta'))
    this.id = this.$route.query.id
    this.shopDetails()
  },
  methods: {
    carouselChange(e) {
      console.log(e);
      this.shopIndex = e
    },
    shopDetails() {
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tproductInfo/findById",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang'),
          id: this.id
        }
      ).then((res) => {
        this.infoDeta = res.data
        // let info = res.data.list;
        this.thumbnailList = this.infoDeta.thumbnail.split('|')
        this.imgShop = this.imgUrl+this.thumbnailList[0]
      });
    },
    shopDetaBig(item,index) {
      console.log(item,index);
      this.$refs.myCarousel.setActiveItem(index);
      this.shopIndex = index
      this.imgShop = this.imgUrl+item
    }
  },
}
</script>

<style lang="scss" scoped>
.page-box {
  .pro-men{
    background: #BCAA94;
    height: 66px;
    line-height: 66px;
    color: #fff;
    font-size: 20px;
    .pro-text{
      width: 1200px;
      margin: 0 auto;
    }
    .home-icon{
      font-size: 30px;
      margin-right: 10px;
    }
  }
  
  .pro-intr{
    margin-top: 15px;
    .pro-intr-cont{
      width: 1400px;
      margin: 0 auto;
      display: flex;
      .left{
        border: 1px solid #BCAA94;
        border-radius: 8px;
        width: 675px;
        height: 496px;
        .pro-intr-img{
          width: 675px;
          height: 496px;
          border-radius: 8px;
        }
      }
      .right{
        width: 810px;
        margin-left: 30px;
        .titlea{
          font-size: 26px;
          color: #707070;
          height: 90px;
        }
        .cont{
          .titlesc{
            background: rgba(30, 52, 101, 0.10);
            font-size: 19px;
            color: #999999;
            height: 56px;
            line-height: 56px;
            padding-left: 15px;
          }
          .introduce-box{
            display: flex;
            font-size: 15px;
            padding: 15px;
            border: 1px solid #BCAA94;
            border-top: none;
            height: 200px;
            .introduce-left{
              color: #999999;
              width: 50px;
            }
            .introduce-right{
              width: calc(100% - 50px);
              height: 200px;
              overflow: auto;
              line-height: 30px;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  .contact{
    background: #BCAA94;
    width: 810px;
    height: 63px;
    line-height: 63px;
    color: #fff;
    text-align: center;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    .contact2{
      font-size: 18px;
      margin: 0 20px 0 10px;
    }
    .contact3{
      font-size: 21px;
    }
  }
  .scroll-box{
    width: 1400px;
    margin: 5px auto 0;
    .scroll{
      display: flex;
      .list{
        width: 146px;
        height: 143px;
        cursor: pointer;
        margin-right: 5px;
        overflow: hidden;
        border-radius: 8px;
        border: 2px solid #fff;
        img{
          width: 100%;
          height: 100%;
          transition: 0.4s ease-in-out;
        }
      }
      .active{
        border: 2px solid #fe5716;
      }
      .list:hover{
        border: 2px solid #fe5716;
      }
      .list:hover img{
        transform: scale(1.05);
      }
    }
  }
}

.shop-box{
  width: 1400px;
  margin: 30px auto 0;
  display: flex;
  .left{
    width: 920px;
    .cont{
      border: 1px solid #BCAA94;
      border-radius: 8px;
      width: 900px;
      padding: 10px 10px;
      .cont-padding{
        width: 900px;
        height: 920px;
        overflow: auto;
        padding: 10px 10px;
      }
    }
  }
  .right{
    width: 566px;
    margin-left: 20px;
    .cont{
      background: #F3F2ED;
      padding: 20px;
      height: 880px;
    }
  }
  .titlesm{
    font-size: 22px;
    margin-bottom: 20px;
    img{
      position: relative;
      top: 3px;
      margin-right: 10px;
    }
  }
}
.plush-box {
    text-align: center;
    .plush-top {
      width: 336px;
      height: 339px;
      background-color: #fff;
      border-radius: 89px 10px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .plush-bot {
      cursor: pointer;
      width: 336px;
      height: 60px;
      line-height: 60px;
      background-color: #e6e5e1;
      color: #9d856b;
      font-size: 31px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 10px;
    }
  }
  .plush-r{
    margin-top: 40px;
  }
</style>
